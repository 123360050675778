import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => (
  <div className="container-center">
    <br/>
    <br/>
    <br/>
    <h4 className="text-bold">Votre espace de déclaration de sinistre en ligne de votre Assurance Garantie Achats sera bientôt disponible</h4>
    <br/>
    <hr className="hr-home"/>
    <br/>
    <h5>Vous pouvez tout de même effectuer votre déclaration de sinistre</h5>
    <br/>
    <h5 className="text-bold">Par téléphone :</h5>
    <p className="remove-margin-top-bootom">0 969 371 651 (numéro non surtaxé)</p>
    <p className="remove-margin-top-bootom">du lundi au vendredi de 8h à 19h</p>
    <br/>
    <h5 className="text-bold">Par email :</h5>
    <p className="remove-margin-top-bootom">garantie.achats@spb.fr</p>
    <br/>
    <h5 className="text-bold">Par voie postale :</h5>
    <p className="remove-margin-top-bootom">SPB Oney Garantie Achats</p>
    <p className="remove-margin-top-bootom">CS 90000</p>
    <p className="remove-margin-top-bootom">76095 Le Havre Cedex</p>
    <br/>
    <br/>
    <br/>
  </div>
);

export default HomePage;
