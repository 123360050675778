import React from "react";

const PageNotFound = () => (
  <div className="container-center">
    <br />
    <br />
    <br />
    <h1>Oops! Page not found.</h1>
  </div>
);

export default PageNotFound;
