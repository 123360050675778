import React from 'react';
import LogoOney from '../../assets/img/Logo_Oney.png';

const Header = () => {
    return (
        <div className="d-flex flex-column flex-wrap flex-md-row align-items-center p-2 px-md-4 mb-3 bg-white shadow-sm ">
            <h5 className="my-4 mr-md-1 "><img className="left-logo" src={LogoOney} height="60" alt="" /></h5>
            <h3 className="my-4 mr-md-auto font-weight-normal">Espace Garantie Achats</h3>
        </div>

    )
}

export default Header
