import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import App from "./components/App";
import "./index.css";


render(
    <Router>
      <App />
    </Router>,
     document.getElementById("root")
  );